<template>
  <div>
    <portal to="title-page">
      <h1>Cupones</h1>
    </portal>
    <v-data-table
      v-if="cupones != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="cupones"
      :search="search"
      item-key="id"
      fixed-header
      class="elevation-1"
      :custom-filter="filterCupones"  
    >
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col cols="12" md="4" sm="12">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify "
                label="Search"
                color="#f88511"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="offset-md-2" md="6" sm="12">
              <router-link :to="{ path: 'cupones/create' }">
                <v-btn dark small color="#f88511">
                  <v-icon>mdi-plus-thick</v-icon>Cupon
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        {{item.type}}
        <div v-if="item.type == 'tour'">{{item.tour}}</div>
      </template>
      <template v-slot:item.dateStart="{ item }">
        {{formatDate(item.dateStart)}}
      </template>
      <template v-slot:item.dateEnd="{ item }">
        {{formatDate(item.dateEnd)}}
      </template>
      <!-- ----  acciones ----- -->
      <template v-slot:item.acciones="{ item }">
        <v-icon
          class="mr-2"
          @click="$router.push({path:`/backend/admin/cupones/${item.id}/edit`})"
          color="#212121"
        >
        mdi-pencil
        </v-icon>
        <v-icon 
          class="mr-2"
          color="success"
          @click="$router.push({path:`/backend/admin/cupones/${item.id}/images`})"
        >
          {{mdiImageMultiple}}
        </v-icon>
        <v-icon class="mr-2" color="red" @click="deleteCupon(item)">mdi-delete</v-icon>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          @click="setStatus(item)"
          small
          :color="item.status == 'active' ? 'green' : 'red' || this.itemIdStatus.status == 'inactive' ? 'red' : 'green'"
          dark
        >
          {{ item.status }}
          <v-icon small>{{mdiPencil}}</v-icon>
        </v-chip>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn color text v-bind="attrs" @click="snackbar.show = false">Cerrar</v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-dialog v-model="dialogStatus" max-width="400px">
        <v-card>
          <v-card-title>Status</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-select
              v-model="statusCupon"
              :items="selectStatus"
              item-text="text"
              label="Status"
              item-value="type"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn color="black" text @click="closeDialogStatus()">Close</v-btn>
            <v-btn color="primary" text @click="statusChange(statusCupon)">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import Cupones from "@/models/Cupones.js";
import { mdiPencil, mdiImageMultiple } from "@mdi/js";
import firebase from 'firebase/app';
import lodash from 'lodash';
import 'firebase/firestore';


export default {
  name: "Cupones",
  data() {
    return {
      /***  iconos  ****/
      mdiImageMultiple:mdiImageMultiple,
      mdiPencil: mdiPencil,
      cuponModel: new Cupones(),
      // moment: new moment(),
      /**** table data ******/
      date : firebase.firestore.Timestamp.fromDate(new Date),
      cupones: null,
      selected: [],
      statusCupon: "",
      selectStatus: [
        { type: "active", text: "active" },
        { type: "inactive", text: "inactive" },
      ],
      search: "",
      tableHeaders: [
        {
          text: "Tipo",
          align: "start",
          sortable: true,
          value: "type",
        },
        // {
        //   text: "Nombre",
        //   align: "start",
        //   sortable: true,
        //   value: "name",
        // },
        {
          text: "Codigo",
          align: "start",
          sortable: false,
          value: "code",
        },
        // {
        //   text: "Porcentaje",
        //   align: "start",
        //   sortable: false,
        //   value: "percent",
        // },
        {
          text: "Fecha inicio",
          align: "start",
          sortable: true,
          value: "dateStart",
        },
        {
          text: "Fecha fin",
          align: "start",
          sortable: false,
          value: "dateEnd",
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: "acciones",
        },
      ],
      itemIdStatus: "",
      dialogStatus: false,
      /******  Mensajes  *******/
      snackbar: {
        show: false,
        message: "",
        color: "",
      },
    };
  },
  methods: {
    deleteCupon(item) {
      this.$dialog
        .confirm(`¿Desea eliminar el cupon <strong>${item.name}</strong>?`, {
          loader: true,
        })
        .then((dialog) => {
          this.cuponModel
            .delete(item.id)
            .then(() => {
              dialog.close();
              this.snackbar.show = true;
              this.snackbar.color = "green";
              this.snackbar.message = `El cupon se elimino correctamente`;
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
              dialog.close();
              this.snackbar.show = true;
              this.snackbar.color = "red";
              this.snackbar.message = `Error al eliminar el cupon, por favor contacte al Administrador si el problema persiste`;
            });
        });
    },
    statusChange(itemStatus) {
      let loading = this.$loading.show();
      this.cuponModel
        .update(this.itemIdStatus.id, { status: itemStatus })
        .then(() => {
          loading.hide();
          this.snackbar.show = true;
          this.snackbar.color = "green";
          this.snackbar.message = `El estatus del cupon se actualizo correctamente`;
          this.dialogStatus = false;
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
          this.snackbar.show = true;
          this.snackbar.color = "red";
          this.snackbar.message = `Error al cambiar el status del cupon, por favor contacte al Administrador si el problema persiste`;
        });
    },
    setStatus(item) {
      this.itemIdStatus = item;
      this.dialogStatus = true;
    },
    closeDialogStatus() {
      this.dialogStatus = false;
    },
    formatDate(value){
      //let date = value.seconds || false;
      if(lodash.has(value, 'seconds')){
        return this.$moment(value.toDate()).format('DD-MM-YYYY');
        // return this.$moment(String(value)).format('DD-MM-YYYY');

      }
    },
    filterCupones(value, search) {
      if (value != null && search != null) {
        if (value.seconds) {
          return this.$moment(value.toDate()).format('DD-MM-YYYY').indexOf(search) !== -1;
        }
        return typeof value === 'string' && value.indexOf(search) !== -1
      }
    },
  },
  mounted() {
    this.cuponModel.all().onSnapshot((qsnap) => {
      this.cupones = [];
      qsnap.forEach((doc) => {
        let cupon = doc.data();
        this.cupones.push(cupon);
      });
    });
  },
  created() {
    this.$store.commit("SET_LAYOUT", "admin");
  },
};
</script>
<style>
a {
  text-decoration: none;
  /* quita el subrayado de routerlink */
}
.table-top-bar {
  text-align: right;
  padding: 16px 16px 0px 16px;
}
</style>